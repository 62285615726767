import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "../../../utils/breakpoints/"
import { colors } from "../../../utils/variables/"

// Icons
import Check from "../../../assets/icons/checkmark.svg"

// Downloads
import InstructionsForUse from "../../../assets/downloads/sexual-health-test/Visby-Medical-Sexual-Health-Point-of-Care-Instructions-for-Use.pdf"

const StyledTopBullets = styled.div`
		flex: 1 0 30%;
		max-width: 550px;
		min-width: 280px;
		padding-top: 4px;
		margin-top: -40px;
		width: 100%;
		color: ${colors.charcoal};

		h1 {
			color: ${colors.white};
			font-size: 36px;
			padding: 24px 0px 5px;
			text-align: center;
	  
			${breakpoint.small`
				text-align: left;
			`}
		}
	
		h2 {
			color: ${colors.white};
			font-size: 22px;
			padding-bottom: 36px;
			text-align: center;
	  
			${breakpoint.small`
				text-align: left;
			`}
		}
	
  	.title {
	 	text-align: center;
		 font-weight: 700;
		 margin: 0;
		 padding: 0;
		 font-size: 19px;
		 line-height: 1.2em;
	
		${breakpoint.small`
			display: block;
			text-align: left;
			font-size: 22px;
		`}
		
		&::before {
            content: url(${Check});
            margin-right: 8px;

			${breakpoint.small`
				margin-right: 8px;
			`}
		}
	}

	.questions {
		font-weight: 700; 
		font-size: 18px;
		text-align: center;
  
		${breakpoint.small`
			text-align: left;
		`}
  	}
  	
	p {
		font-size: 16px;
		line-height: 1.4rem;
		padding-bottom: 36px;
		margin: 0 20px;
		text-align: center;
  
		${breakpoint.small`
			margin-left: 0;
			text-align: left;
		`}
	}
  	
    a {
      color: ${colors.orange};

      &:hover {
        color: ${colors.purpleDark};
      }
    }
    
 	.detail {
		${breakpoint.small`
			margin-left: 2rem;
			text-align: left;
		`}
		a {
		  color: ${colors.orange};

		  &:hover {
			color: ${colors.charcoal};
		  }
		}
    }

    ${breakpoint.medium`
        width: 50%;
    `}
    }
`


const TopBullets = props => {
	return (
		<StyledTopBullets>
			<p>Every minute, every bed counts - Get PCR STI results in 28 minutes to improve patient length of stay, relieve resource strain and patient satisfaction scores.</p>
			<div data-aos="fade-up" data-aos-delay="100">
				<p className="title">Accurate</p>
				<p className="detail">Delivers ~97% accuracy, see our <a href={InstructionsForUse} target="_blank" rel="noopener noreferrer">Instructions for Use</a> for details</p>
			</div>
			<div data-aos="fade-up" data-aos-delay="200">
				<p className="title">Fast</p>
				<p className="detail">Results in under 30 minutes, while the patient is present.</p>
			</div>
			<div data-aos="fade-up" data-aos-delay="300">
				<p className="title">CLIA-waived</p>
				<p className="detail">Easy to use at the point-of-care; no extra instrument or maintenance needed.</p>
			</div>
			<div data-aos="fade-up" data-aos-delay="400">
				<p className="title">Antibiotic stewardship</p>
				<p className="detail">Avoid unnecessary antibiotics.</p>
			</div>
			<p className="questions" data-aos="fade-up" data-aos-delay="500"> Questions? Call us 1-833-GoVisby (1-833-468-4729)</p>
		</StyledTopBullets>
	)
}
export default TopBullets
